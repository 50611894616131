<template>
  <div class="home-page">
    <div v-if="showDownloadBar" class="download-bar">
      <img class="download-bar-icon" src="../assets/win_icon.jpg" alt="app-icon" />
      <div class="app-full-name-wrap">
        <span class="app-full-name">Helpslotwin</span>
      </div>
      <div class="download-bar-btn" @click="downloadApp">Google Store Download</div>
      <div class="download-bar-close" @click="showDownloadBar = false"></div>
    </div>
    <div class="main-container">
      <div class="header">
        <img src="../assets/banner_mobile.webp" />
      </div>

      
      
      <div class="filterTab">
        <div class="filterIcons">
          <img @click="updateOrderBy(true)" class="reverseArrow" src="../assets/filter_down.webp" />
          <img @click="updateOrderBy(false)" class="reverseArrow" src="../assets/filter_up.webp" />
        </div>
        <div class="filterInput">
          <input class="gameSearch" v-model="searchText" placeholder="⌕ Search for game" />
        </div>
      </div>
      <!-- company 列表-->


      <!-- <div class="company-wrap">
        <div class="providerContainer" v-for="(item) in companys" @click="fetchGames(item)">
          <div class="providerLogo" onclick="">
            <img :src="item.logo" />
          </div>
          <div class="providerName">{{ item.name }}</div>
        </div>
      </div> -->

      <div v-if="companys.length > 0" class="provider-list">
        <div :class="`provider-item ${activeProvider && activeProvider.id === item.id ? 'active' : 'inactive'}`"
          v-for="item in companys" :key="item.id" @click="activeProvider = item">
          <img class="provider-icon" :src="item.logo" />
          <div class="provider-name">
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>


      <!-- 游戏 列表-->
      <div v-for="(item) in games">
        <!-- {{ item.name }} -->
        <div class="game">
          <div class="gameContainer">
            <div class="gameIcon">
              <img :src="item.logo" />
            </div>
            <div class="jackpotMeter">
              <div class="meterBadge">
                <img class="meterBadgeImg" src="../assets/badge_meter.webp" />
                <div class="arrowContainer">
                  <img :class="{ 'reverseArrow': !item.up }"
                    :src="item.up ? require('@/assets/green_arrow.webp') : require('@/assets/red_arrow.webp')" />
                </div>
              </div>
              <div class="meterBar">
                <div class="gameName">{{ item.name }}</div>
                <div class="jackpotPercentage">Jackpot Meter:<span>{{ item.value }}%</span></div>
                <img src="../assets/meter_bar.webp" />
                <div class="meter">
                  <div :class="{ 'redMeter meterBody': !item.up, 'greenMeter meterBody': item.up }"
                    :style="{ width: item.value + '%' }">{{ item.value }}%</div>
                  <div class="meterEnd">
                    <!-- <img src="../assets/red_end.webp" /> -->
                    <img :src="item.up ? require('@/assets/green_end.webp') : require('@/assets/red_end.webp')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="history">
              <div class="historyDetails timeframe">
                <div>10 mins</div>
                <div>1 hr</div>
                <div>3 hrs</div>
                <div>6 hrs</div>
              </div>
              <div class="historyDetails">
                <div>:</div>
                <div>:</div>
                <div>:</div>
                <div>:</div>
              </div>
              <div class="historyDetails percentage">
                <div :class="{ 'greenText': item.min10 >= 0, 'redText': item.min10 < 0 }">{{ item.min10 }}%</div>
                <div :class="{ 'greenText': item.hr1 >= 0, 'redText': item.hr1 < 0 }">{{ item.hr1 }}%</div>
                <div :class="{ 'greenText': item.hr3 >= 0, 'redText': item.hr3 < 0 }">{{ item.hr3 }}%</div>
                <div :class="{ 'greenText': item.hr6 >= 0, 'redText': item.hr6 < 0 }">{{ item.hr6 }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import { getGames, getManuf } from "@/api";
import { debounce } from 'lodash-es';

export default {
  data() {
    return {
      games: [],
      orderByAsc: null,
      time: 0,
      companys: [],
      activeProvider: null,
      searchText: '',
      timer: null,
      showDownloadBar: false,
      userAgent: navigator.userAgent,
      // isInApp: true,
    };
  },

  watch: {
    activeProvider() {
      if (this.activeProvider) {
        this.fetchGames();
      }
    },
    searchText() {
      this.searchGame();
    },
  },
  created() {
    this.timer = setInterval(() => {
      this.time = this.time + 1;
      this.fetchGames();
      // this.$socketServe.send({ code: 'REFRESH_GAME', data: this.activeProvider?.id }, true);
    }, 10000)
    // 注册接收到数据的回调函数
    // this.$socketServe.registerCallBack('GAME_VALUE', data => {
    //   console.log(data);
    //   let dataMap = {};
    //   data.map(item => {
    //     dataMap[item.id] = item;
    //   })
    //   let newGames = this.games;
    //   newGames = newGames.map(item => {
    //     item.value > dataMap[item.id].value ? item.up = false : item.up = true;
    //     return {
    //       ...item,
    //       ...dataMap[item.id],
    //     }
    //   })
    //   if (this.time >= 10) {
    //     this.time = 0;
    //     if (this.orderByAsc !== null) {
    //       if (this.orderByAsc) {
    //         newGames = newGames.sort((a, b) => {
    //           return b.value - a.value;
    //         })
    //       } else {
    //         newGames = newGames.sort((a, b) => {
    //           return a.value - b.value;
    //         })
    //       }
    //     }
    //   }
    //   this.games = newGames;
    // })
  },

  mounted() {
    let _this = this;
   
    const u = navigator.userAgent,
      isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      isAndroidApp = u.indexOf('AgentWeb') > -1 ,
      isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    if (isiOS) {
      _this.showDownloadBar = false;
      try {//app内
        window.webkit.messageHandlers.getMessage.postMessage(jsonObj)
      } catch (error) {//浏览器内
      }
    } 
    if(isAndroid){
      if(isAndroidApp){//app内
        this.showDownloadBar = false;
      } else {
        //浏览器内 .9 暂不展示下载
        this.showDownloadBar = false;
      }
    }

    this.fetchCompanys();
  },

  methods: {
    fetchCompanys() {
      getManuf().then(res => {
        this.companys = res.data || [];
        if (this.companys.length > 0) {
          this.activeProvider = this.companys[0]
        }
      });
    },
    fetchGames() {
      getGames({
        manuf: this.activeProvider?.code,
        search: this.searchText || undefined,
      }).then(res => {
      let newGames =res.data || [];
      // newGames = newGames.map(item => {
      //   item.value > dataMap[item.id].value ? item.up = false : item.up = true;
      //   return {
      //     ...item,
      //     ...dataMap[item.id],
      //   }
      // })
      if (this.time >= 10) {
        this.time = 0;
        if (this.orderByAsc !== null) {
          if (this.orderByAsc) {
            newGames = newGames.sort((a, b) => {
              return a.value - b.value;
            })
          } else {
            newGames = newGames.sort((a, b) => {
              return b.value - a.value;
            })
          }
        }
      }
      this.games = newGames;
      });
    },
    updateOrderBy(bool) {
      console.log("点击排序");
      this.orderByAsc = bool;
      this.time = 10;
    },

    searchGame: debounce(function () {
      this.fetchGames();
    }, 500),

    downloadApp(){
      //新页面打开链接
      window.open('https://play.google.com/store/apps/details?id=com.help.slot');
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
  

};


</script>

<style lang="less">
.home-page {
  background-color: #040B26;
  font-family: 'Rubik', sans-serif;
  overflow-x: hidden;
}

.providers::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.providers {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main-container {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
}

.header img {
  width: 100%;
  margin-bottom: 5px;
}

.download-bar {
  //固定在顶部
  // position: fixed;
  position: relative;
  width: 100%;
  height: 54px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background: #374057;
  box-sizing: border-box;
  .download-bar-icon{
    width: 42px;
    height: 42px;
    margin-right: 10px;
    border-radius: 6px;
  
  }
  .app-full-name-wrap{
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .download-bar-btn{
    padding: 4px 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    background: #fdb813;
    border-radius: 2500px;
  }
  .download-bar-close {
    width: 22px;
    height: 22px;
    margin-left: 6px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAABHNCSVQICAgIfAhkiAAAA1FJREFUWEfNma9zVDEQgHkSLEiQwN8AFgsWCxYLrgXFgWsHx+CorW1tbWuxPSTI1rby2O+xKXnv5SXZJMddZjJ3c5cf3242u5uku1VZVqvVAxnioVQ+70p9HBjyXH67lPpL6s+u6/gsLl1JTwEF7onWewVjXEifM6oIgDCmYoJW2Ocyw1PTLPHGp/L3sQU+G1qAgX0m9U5DYDfUlXw5EfDjnLGT0KrdN2qzOWPWtMHWv6a0HoXWTfZ2TdqdEw6t78c26yy0AD+Szmh4HeaQWo0oeBB6QxoeCzILPoFWG36/IQ2HwHfFVBDgpoSgASZQbEs5F+j9WWjR8gv5E9e2beVQwE8c1I2mt8wsQmaycK7Qh34tLQnN21oI+d+B66FVy58NtNfSlvBLMCBKWvYAfVhqchb63jbMu4O2HbTVlskVjlRg/DgBKAccYAJH7w1EWawsK5xb+nkd9CeVPLczofaHayyT54APgBWaAPYud1JpdyHz7nYaSHBzljJxQwnwCbBCs0Kh/DvGsgAau3ppIda2pyL1gd9vBnwO+JX0LUlxD4HGpkq9BiZy4EesEXhrYHR0BjQ2hW2VlpCtYuOsHkFhEIJlvlINO74l0N9Kab1+QY2Ox20AzJBXraAZLAreCLjXQ0vov/amUWu0Qa3+OLr4LaFTmq7Z8AMhgP4iv1hCaUgLuTbdAvx6Ld4DqQjRYircbQxKpYtlrN571Eif8sOTAKQC1czZ++nSiJgCdhpuDd5HRLIza+6RC5wCLwlsC5flkUtzP5dbBlmeLnkq0oVyFWuWdyn7ZKc6n84EDmq8wDQH+TRatpxcyCc4BPyWykHYkl5y7cud3X2pHD4sl0H/Ti4NdnSuWdW0G54RFRptf5BaG2hqwOb6cib9ODmNK3ip+1sHqD9m+N7DtWiQX7cWYCka3vMHDV2LsTHYlNtgJpgFmy9+l6dmQsDB8W8SHOC90D117H56k+CzwCg15yXgf2s8CpyEVlPBxnkRqDn85m7OpTQkRRjY8Lhz8qHI8yq4QyLYOuwc7R7517kxKbOhVesEIMBL70lCLBwUAM5+BDVBe1oHntsh4C3ZoRsCQGDJ/LJhXeciaF9dmo9j7+5tnE/fhFh68m/3Nk6wqHob/wPoyJpm3M++mQAAAABJRU5ErkJggg==) no-repeat;
    background-size: 100% 100%;
  }
}
.userAgent{
  color: #fff;
}

// .backToTop {
//   position: sticky;
//   z-index: 5;
//   bottom: 10px;
//   width: 16vw;
//   margin-left: 76vw;
//   opacity: 0.4;
//   transition: opacity 0.4s, transform 0.4s;
// }

// .backToTop:active {
//   opacity: 1;
//   transform: scale(1.05);
//   cursor: pointer;
// }

// .backToTop img {
//   width: 100%;
// }

.providerContainer {
  background-color: #374057;
  width: 90px;
  height: 150px;
  margin-right: 10px;
  border-radius: 5px;
  flex-shrink: 0;
  color: #CCBDBD;
  font-family: Rubik;
  /* font-size: 1.2vw; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0.5vw; */
}

.providerContainer:active {
  background-color: #D8DEEC;
}

.providers {
  position: sticky;
  top: 0;
  background-color: #040B26;
  z-index: 5;
  padding: 5px 0;
}

.gameContainer {
  display: flex;
  background-color: #374057;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: calc(100% - 10px);
  height: 80px;
}

.gameIcon {
  // height: 80px;
  width: 80px;
  border-radius: px;
  margin-right: 5px;
  overflow: hidden;
}

.gameIcon img {
  width: 100%;
}

.jackpotMeter {
  display: flex;
  align-items: center;
}

.meterBadge {
  position: relative;
}

.meterBadgeImg {
  height: 38px;
  position: relative;
  z-index: 1;
}

.meterBar {
  position: relative;
}

.meterBar img {
  width: 150px;
  margin-left: -5vw;
}

.meter {
  height: 11px;
  position: absolute;
  width: 123px;
  top: 4px;
  display: flex;
  align-items: center;
}

.meterBody {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.66);
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: width 0.5s;
  z-index: 2;
}

.redMeter {
  background: linear-gradient(180deg, #9C2601 0%, #FE4207 100%);
}

.greenMeter {
  background: linear-gradient(180deg, #0B9609 0%, #13E00F 100%);
}

.meterEnd img {
  height: 11px;
  width: auto;
  margin-left: 0px;
}

.history {
  display: flex;
  height: auto;
  /* margin-left: auto; */
}

.historyDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 3px;
  font-size: 12px;
  color: #C3B5B7;
}

.timeframe {
  text-align: right;

}

.percentage {
  width: 10px;
}

.arrowContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 40px;
}

.arrowContainer img {
  width: 100%;
  transition: all 0.2s;
}

.arrow {
  transition: tranform 0.2s;
}

.reverseArrow {
  transform: rotate(180deg);
}

.gameName {
  position: absolute;
  top: -30px;
  font-size: 12px;
  font-weight: 600;
  color: #C3B5B7;
  height: 25px;
  align-items: center;
  display: flex;
  /* background: red; */
}

.jackpotPercentage {
  position: absolute;
  top: 20px;
  font-size: 12px;
  color: #C3B5B7;
}

.greenText {
  color: #40FF3C;
}

.redText {
  color: #FF6060;
}


.swiper {
  width: 100%;
  height: 100px;
}

.swiper-slide {
  text-align: center;
  font-size: 12px;
  background-color: #374057;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.providerLogo img {
  display: block;
  /* width: 9.5vw; */
  height: 100%;
  object-fit: cover;
  /* margin-bottom: 2vw; */
  /* margin-top: 2vw; */
}

.providerLogo {
  display: flex;
  justify-content: center;
}

.providerName {
  text-align: center;
  font-size: 12px;
}


.buttons {
  display: flex;
}

.opacityZero {
  opacity: 0;
}

.filterTab {
  display: flex;
  justify-content: space-between;
  height: 9.5vw;
}

.filterIcons {
  display: flex;
}

.filterIcons img {
  height: 100%;
  margin-right: 0.5vw;
}

.gameSearch {
  background-color: #374057;
  height: 80%;
  position: relative;
  z-index: 6;
  border: none;
  border-radius: 20px;
  padding: 2px;
  font-family: "Rubik", sans-serif;
  color: #CCBDBD;
  font-size: 15px;
  width: 150px;
}

.gameSearch::placeholder {
  color: #CCBDBD;
}

.gameSearch:focus-visible {
  outline: none;
}

.company-wrap {
  display: flex;
  align-items: center;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}

.company-wrap .company {
  margin-right: 4px;
}

.company-wrap .company img {
  width: 40px;
}

.provider-list {
  display: flex;
  align-items: center;
  width: 355px;
  margin: 12px 0;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .provider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 13px;
    height: 50px;
    width: 100px;
    text-align: center;
    padding: 6px;
    color: #CCBDBD;
    background: #374057;
    border-radius: 3px;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    .provider-icon {
      display: block;
      width: 90px;
      height: 30px;
    }

    .provider-name {
      display: flex;
      align-items: center;
      height: 28px;
      line-height: 12px;
      font-size: 12px;
    }

    &.active {
      color: #fff;
      background: #4E62E4;
      border-color: #4E62E4;
    }

    &.inactive .provider-icon {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      // filter: gray;
    }
  }
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
